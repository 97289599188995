import React, { useState, useEffect } from "react";
import {
     Button,
     Heading,
     View,
     SelectField,
     CheckboxField,
     TextField,
     TextAreaField,
     Flex,
     useTheme
   } from "@aws-amplify/ui-react";
import { API, Storage } from "aws-amplify"; 
import dayJS from "dayjs";
import partners from "./partners.json";
import {
    createPurchaseOrder as createPurchaseOrderMutation,
    createQueueEntry    
  } from "../graphql/mutations";
  


export default function PurchaseOrderUpload({email}) {
    
    
    const { tokens } = useTheme();
    const [dueDateHasError, setDueDateHasError] = useState(false);
    const [warehouseDateHasError, setWarehouseDateHasError] = useState(false);
    const [userEmail, setUserEmail] = useState();
    const [userNote, setUserNote] = useState();
    const [suppressQB, setSupressQB] = useState(false);

    useEffect(() => {
        setUserEmail(email);
        setUserNote('Please confirm receipt of PO to ' + email + ' and advise ETA. Thank you!');
      }, [email]);

    const validateDate = (e) => {
        
        const today = dayJS().startOf('day');
        const setDate = dayJS(e.currentTarget.value).startOf('day')
        const dayDiff = setDate.diff(today);
        switch (e.target.name) {
            case 'duedate':
                setDueDateHasError(dayDiff <= 0 ? true : false)
                break;
            case 'eta':
                setWarehouseDateHasError(dayDiff <= 0 ? true : false)
                break;
            default:
                break;
        }

    };

    async function createPurchaseOrder(event) {
        const uploadDate = dayJS().format('YYYY-MM-DD-HH-mm-ss');
        const Email = userEmail;
        event.preventDefault();
        const form = new FormData(event.target);
        const file = form.get("file");
    
        if (!file.name) {
          window.alert("Please select a file");
          return null;
        };
        const data = {
          partner: form.get("partner"),
          user: Email,
          dc: form.get("dc"),
          file: `po-uploads/${form.get("partner").replace(/\W/g,"")}-${form.get("dc")}_${form.get("eta")}_${uploadDate}.csv`,
          time: uploadDate,
          duedate: form.get("duedate"),
          eta: form.get("eta"),
          status: 'Pending',
          ponumber: '---',
          shipvia: form.get("shipvia"),
          discountpercent: form.get("discountpercent"),
          supressupload: suppressQB,
          note: form.get("note")
        };

        const queueData = {
          partner: form.get("partner"),
          file: `po-uploads/${form.get("partner").replace(/\W/g,"")}-${form.get("dc")}_${form.get("eta")}_${uploadDate}.csv`,
          user: Email,
          uploadType: 'Purchase Order',
          dc: form.get("dc"),
          time: uploadDate,
          status: 'Pending'
        };
    
        
        if (!!data.file) await Storage.put(`po-uploads/${data.partner.replace(/\W/g,"")}-${data.dc}_${data.eta}_${uploadDate}.csv`, file);
        await API.graphql({
          query: createPurchaseOrderMutation,
          variables: { input: data },
        });
        await API.graphql({
          query: createQueueEntry,
          variables: { input: queueData },
        });

        setSupressQB(false); 
        event.target.reset();
      }
    

    return (
        <Flex 
    direction="column"
    justifyContent="space-around"
    margin="2rem 0" 
    border="1px solid var(--amplify-colors-teal-80)"
    width="45rem"
    padding="1.5rem"
    backgroundColor="var(--amplify-colors-teal-10)"
    boxShadow="3px 3px 5px 4px var(--amplify-colors-neutral-40)"
    >  

    
<Heading level={4} fontWeight={tokens.fontWeights.normal}>Upload a PO</Heading>
  <View as="form" onSubmit={createPurchaseOrder}>
  <Flex direction="column" justifyContent="center" alignItems="baseline" >
    <Flex direction="row" justifyContent="space-between" alignItems="baseline" width="100%" alignContent="space-between">
      <SelectField
      label="Vendor"
      name="partner"
      fontWeight={tokens.fontWeights.light}
      isRequired={true} 
      >
        {partners.map((name) => {
          return (
            <option key={name.Name} value={name.Name}>{name.Name}</option>
          );
        })}
      </SelectField>
      <TextField name="duedate" label="Vendor Due Date" type="date" fontWeight={tokens.fontWeights.light} isRequired={true} onChange={validateDate} hasError={dueDateHasError} errorMessage="Date must be greater than current date" />
      <SelectField
        fontWeight={tokens.fontWeights.light}
        label="Warehouse"
        name="dc"
        isRequired={true} 
        >
        <option value=""></option>
        <option value="CDC">CDC</option>
        <option value="MDC">MDC</option>
        <option value="PDC">PDC</option>
      </SelectField>
    </Flex>
    <Flex direction="row" justifyContent="space-between" alignItems="baseline" width="100%" alignContent="space-between">
      <TextField name="eta" label="Warehouse ETA" type="date" fontWeight={tokens.fontWeights.light} isRequired={true} onChange={validateDate} hasError={warehouseDateHasError} errorMessage="Date must be greater than current date" />
      <SelectField
        label="Ship via"
        name="shipvia"
        width="250px"
        fontWeight={tokens.fontWeights.light}
        isRequired={true} 
      >
        <option value="standard">Delivered</option>
        <option value="wppickup">WP Pick-up</option>
        <option value="partnerbillto">Arrange & Bill WP</option>
      </SelectField>
      <TextField name="discountpercent" label="Discount %" type="text" width="85px" defaultValue="0" isRequired={true} fontWeight={tokens.fontWeights.light} />
    </Flex>
    <Flex direction="row" justifyContent="center" alignItems="baseline" width="100%">
      <TextAreaField name="note" label="PO Instructions" type="text" size="small" rows="2" width="100%" maxLength="255" fontWeight={tokens.fontWeights.light} defaultValue={userNote}/>
    </Flex>
    <Flex direction="row" justifyContent="space-between" alignItems="flex-end" width="100%">
      <CheckboxField
        label="Supress QB Upload"
        name="supressupload"
        value="yes"
        onChange={(e) => setSupressQB(e.target.checked)}
        labelPosition="bottom"
        size="small"
        fontWeight={tokens.fontWeights.light}
      />
      <View
          name="file"
          as="input"
          type="file"
          accept="text/csv"
          style={{ alignSelf: "end" , cursor: "pointer" }}
          fontWeight={tokens.fontWeights.light}
          fontSize={tokens.fontWeights.small}
      />
      <Button type="submit" variation="primary"  style={{alignSelf: "flex-end"}} fontWeight={tokens.fontWeights.light} >
        Submit
      </Button>
    </Flex>
  </Flex>  
  </View>
  </Flex>
    )
}